import React from "react";

import underline from "images/icons/zigzag-path.svg";
import profileAboutPhoto from "images/images/inna-valouskova-o-mne.jpg";
import instagramIcon from "images/icons/instagram-icon.svg";
import rightIcon from "images/icons/arrow-right-icon.svg";
import emailIcon from "images/icons/email-icon.svg";
import smoothwebLogo from "images/logo/smoothweb-logo.svg";
import smoothwebText from "images/logo/smoothweb-text.svg";

export const AboutMeSection = () => (
  <section className="about-me">
    <div className="heading-container">
      <h2 className="heading">O mně</h2>
      <img src={underline} alt="ikona podtrhnutí" className="underline" />
    </div>
    <div className="text-contact-wrap">
      <div className="text-container">
        <div className="ratio-box">
          <img
            src={profileAboutPhoto}
            alt="Inna Valoušková - O mně"
            className="profile-image"
          />
        </div>
        <div className="content-padding">
          <p className="description-text">
            Vystudovaná ekonomička, milující matka, vášnivá{" "}
            <span className="bold">kuchařka a zahradnice</span>. Myslím, že tyto
            výrazy mě naplno vystihují. Sice se ekonomii nevěnuji, starší dcera
            už vylétla z hnízda, ta mladší nastupuje na gymnázium, ale{" "}
            <span className="bold">má láska a vášeň k jídlu</span> zůstává
            stejná, ba dokonce silnější než kdy před tím.
            <br />
            <br />
          </p>
          <p className="description-text">
            K vaření neodmyslitelně patří i suroviny ze kterých člověk vaří,
            proto se snažím vše co jde vypěstovat sama. Touto cestou bych se s
            Vámi chtěla podělit o svůj{" "}
            <span className="bold">všední i nevšední život</span>.<br />
            <br />
          </p>
          <p className="description-text">
            Snad vás nezklamu, <br />
            <span className="special-text">
              Vaše <span className="green-text">Inna</span>
            </span>
          </p>
        </div>
      </div>
      <div className="contact-container content-padding">
        <h2 className="heading">
          <span className="lateral-underline" />
          KONTAKT
        </h2>
        <div className="socials-wrap">
          <a
            id="instagram_link_2"
            className="instagram-button"
            href="https://www.instagram.com/garden.and.kitchen/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramIcon} alt="instagram ikona" className="icon" />
            <span className="button-text">Instagram</span>
            <img
              src={rightIcon}
              alt="ikona šipky doprava"
              className="arrow-icon"
            />
          </a>
          {/* TODO: EMAIL OBFUSCATOR */}
          <a
            className="email-button"
            target="_blank"
            href="mailto:inna@gardenandkitchen.com"
            rel="noopener noreferrer"
          >
            <img src={emailIcon} alt="ikona emailu" className="icon" />
            <span className="button-text">inna@gardenandkitchen.com</span>
          </a>
        </div>
        <h2 className="heading">
          <span className="lateral-underline smoothweb" />
          WEB VYTVOŘILI
        </h2>

        <div className="smoothweb-wrap">
          <div className="logo-wrap">
            <a
              id="smoothweb_link_text"
              className="smoothweb-button"
              href="https://www.smoothweb.cz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={smoothwebLogo}
                alt="Smoothweb logo, Smoothweeb.cz vývoj webů a webových aplikací"
                className="logo"
              />
              <img
                src={smoothwebText}
                alt="Smoothweb logo, Tvorba webových aplikací na míru - Smoothweeb.cz"
                className="button-text"
              />
            </a>
          </div>
        </div>
        {/* TODO: zprovoznit form */}
        {/* <form id="email-form--contact-form" className="contact-form" ref={(node) => node && node.setAttribute("action", "javascript:void(0);")} method="POST" acceptCharset="UTF-8">
          <input id="email-form--contact-form--name" className="input" type="text" name="Jmeno" autoComplete="on" maxLength={255} placeholder="Vaše jméno" required />
          <input id="email-form--contact-form--email" className="input" type="email" name="Email" autoComplete="on" maxLength={255} placeholder="Váš email" required />
          <textarea id="email-form--contact-form--message" className="input input--textarea" name="Zprava" cols={30} rows={10} maxLength={5000} placeholder="Zpráva" required defaultValue={""} />
          <input id="email-form--contact-form--gotcha" type="hidden" name="_gotcha" />
          <button className="send-button c-button--green" type="submit">
            Odeslat zprávu
          </button>
          <div id="email-success" className="email-success">
            <span>
              Email byl úspěšně odeslán.
              <br />
              Brzy se Vám ozvu :)
            </span>
          </div>
        </form> */}
      </div>
    </div>
  </section>
);

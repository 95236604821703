import React, { useEffect, useState, useRef } from "react";
import { Link, graphql, withPrefix } from "gatsby";
import { Helmet } from "react-helmet";
import { getStringDate } from "utils/formats";
import { useWindowDimensions } from "hooks/useWindowDimensions";

import Img from "gatsby-image";

import rightIcon from "images/icons/arrow-right-icon.svg";
import searchIcon from "images/icons/search-icon.svg";
import arrowIcon from "images/icons/dropdown-arrow-icon.svg";

import Layout from "components/layout";
import "templates/main-page.scss";
import { GeneralOpenGraphMeta } from "components/generalOpenGraphMeta";
import SEO from "components/seo";

import {
  InstagramSection,
  HeroSection,
  EshopSection,
  AboutMeSection,
  BookShowCaseSection,
  PartnersAwardsNewsletterMobile,
  PartnersAwardsNewsletterDesktop,
} from "sections";

const IndexPage = ({ data }) => {
  const { width } = useWindowDimensions();
  const isSmallDevice = width < 800;
  const RESULTS_LIMIT = isSmallDevice ? 6 : 15;

  const accents = "áäčďéěíĺľňóôőöŕšťúůűüýřžÁÄČĎÉĚÍĹĽŇÓÔŐÖŔŠŤÚŮŰÜÝŘŽ";
  const accents_out = "aacdeeillnoooorstuuuuyrzAACDEEILLNOOOORSTUUUUYRZ";
  const accents_map = new Map();
  for (const i in accents)
    accents_map.set(accents.charCodeAt(i), accents_out.charCodeAt(i));

  function removeAccents(str) {
    const nstr = new Array(str.length);
    let x, i;
    for (i = 0; i < nstr.length; i++)
      nstr[i] = accents_map.get((x = str.charCodeAt(i))) || x;
    return String.fromCharCode.apply(null, nstr);
  }

  const originalAllArticles = data.allStrapiArticle.edges;
  originalAllArticles.forEach((element) => {
    element.type = "clanky";
  });
  const originalAllRecipes = data.allStrapiRecipe.edges;
  originalAllRecipes.forEach((element) => {
    element.type = "recepty";
  });
  const originalAll = [...originalAllRecipes, ...originalAllArticles];

  const [actualAll, setActualAll] = useState(originalAll);
  const [sliceLimit, setSliceLimit] = useState(RESULTS_LIMIT);
  const [tagFilter, setTagFilter] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const refCategoryFilter = useRef(null);

  function handleLoadNextClick() {
    setSliceLimit(sliceLimit + RESULTS_LIMIT);
  }

  function handleTagClick(Tag) {
    if (Tag === tagFilter) {
      setTagFilter(undefined);
      setActualAll(originalAll);
    } else {
      setTagFilter(Tag);
      const tagFilteredItems = originalAll.filter((item) => {
        return item.node.tags.find((tag) => tag.Tag === Tag);
      });
      setActualAll(tagFilteredItems);
    }
    setSliceLimit(RESULTS_LIMIT);
    setSearchTerm("");
  }

  const handleSearchChange = (e) => {
    setTagFilter(undefined);
    const queriedItems = originalAll.filter((item) =>
      removeAccents(item.node.title.toLowerCase()).includes(
        removeAccents(e.target.value.toLowerCase())
      )
    );
    setActualAll(queriedItems);
    setSearchTerm(e.target.value);
    localStorage.setItem("searchTerm", e.target.value);
    setSliceLimit(RESULTS_LIMIT);
  };

  useEffect(() => {
    if (searchTerm === "" || searchTerm === undefined) {
      setActualAll(originalAll);
    }
  }, [searchTerm]);

  useEffect(() => {
    const savedTerm = localStorage.getItem("searchTerm");
    if (savedTerm) {
      const queriedItems = originalAll.filter((item) =>
        removeAccents(item.node.title.toLowerCase()).includes(
          removeAccents(savedTerm.toLowerCase())
        )
      );
      setActualAll(queriedItems);
      setSearchTerm(savedTerm);
    }
  }, []);

  const displayLoadMore = sliceLimit < actualAll.length;

  return (
    <>
      <div className="page-main">
        <Layout>
          <SEO />
          <Helmet>
            <meta
              property="og:title"
              content="Blog o receptech a zahrádce | Inna Valoušková"
            />
            <meta property="og:site_name" content="Garden and Kitchen" />
            <meta
              property="og:url"
              content="https://www.gardenandkitchen.com/"
            />
            <meta
              property="og:description"
              content="Recepty a zahrádka od blogerky Inny Valouškové. Blog Garden and Kitchen."
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:image"
              content="https://www.gardenandkitchen.com/assets/open-graph/garden-and-kitchen-og.jpg"
            />
            <meta property="og:image:width" content="1442" />
            <meta property="og:image:height" content="976" />
            <script src={withPrefix("main-page.js")} type="text/javascript" />
          </Helmet>
          <HeroSection />
          <section className="aside d-xl-none content-padding eshop-mobile">
            <EshopSection />
          </section>
          <div className="aside-with-filter-and-recipes page-width-restrictor">
            <section className="aside aside--desktop">
              <div className="item item--first">
                <div className="text-with-decoration text-with-decoration--first">
                  <span className="lateral-underline lateral-underline--big" />
                  <h3 className="big-heading">KATEGORIE</h3>
                </div>
                {data.allStrapiTag && (
                  <ul className="filterable-categories">
                    {data.allStrapiTag.edges.map((tag) => (
                      <li
                        onClick={() => handleTagClick(tag.node.Tag)}
                        key={tag.node.id}
                        className={`filter-item c-tag-item ${
                          tagFilter === tag.node.Tag ? "active" : undefined
                        }`}
                      >
                        {tag.node.Tag}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <EshopSection onDesktop />
              <PartnersAwardsNewsletterDesktop />
            </section>
            <div className="filter-and-recipes-container">
              <section className="filtering">
                <div className="content-box content-padding">
                  <div className="filter-by-text-wrap">
                    <div className="filter-btn">
                      <img
                        src={searchIcon}
                        alt="ikona hledání"
                        className="filter-image"
                      />
                    </div>
                    <input
                      type="text"
                      className="filter-by-text-input"
                      placeholder="Vyhledat článek / recept"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div
                    id="filter-by-category"
                    className="filter-by-category d-xl-none"
                    onClick={(e) => {
                      refCategoryFilter.current &&
                        refCategoryFilter.current.classList.toggle("opened");
                      e.target.classList.toggle("opened");
                    }}
                  >
                    <div className="category-heading">
                      {tagFilter
                        ? `ZMĚNIT KATEGORII (aktivní kategorie: ${tagFilter})`
                        : "VYBRAT KATEGORII"}
                    </div>
                    <img
                      src={arrowIcon}
                      alt="ikona šipky rozbalovací nabídky"
                      className="arrow-icon"
                    />
                  </div>
                </div>
                <div
                  id="category-filter"
                  className="category-filter content-padding d-xl-none"
                  ref={refCategoryFilter}
                >
                  {/* <ul className="filtered-categories">
                    <li className="filter-item c-tag-item">ThisIsHardSet - previously when active</li>
                  </ul> */}
                  {/* <div className="separation-line-wrap content-padding">
                    <span className="separation-line" />
                  </div> */}
                  {data.allStrapiTag && (
                    <ul className="filterable-categories">
                      {data.allStrapiTag.edges.map((tag) => (
                        <li
                          onClick={() => handleTagClick(tag.node.Tag)}
                          key={tag.node.id}
                          className={`filter-item c-tag-item ${
                            tagFilter === tag.node.Tag ? "active" : undefined
                          }`}
                        >
                          {tag.node.Tag}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </section>
              <section className="recipes">
                <div className="recipe-list">
                  {actualAll.slice(0, sliceLimit).map((item) => (
                    <article key={item.node.id} className="recipe-item">
                      <Link
                        className="recipe-content"
                        to={`/${item.type}/${item.node.id}`}
                      >
                        <div className="photo-wrap">
                          <ul className="tags-container">
                            {item.node.tags &&
                              item.node.tags.map((tag) => (
                                <li key={tag.id} className="tag">
                                  {tag.Tag}
                                </li>
                              ))}
                          </ul>
                          <Img
                            fluid={
                              item.node.thumbnailImage.childImageSharp.fluid
                            }
                            alt="recipe"
                            className="photo"
                          />
                        </div>
                        <div className="text-wrap">
                          <h3 className="heading">{item.node.title}</h3>
                          <div className="footer-wrap">
                            <p className="date-text">
                              {getStringDate(
                                item.node.originallyCreatedAt
                                  ? item.node.originallyCreatedAt
                                  : item.node.createdAt
                              )}
                            </p>
                            <img
                              src={rightIcon}
                              alt="ikona šipky doprava"
                              className="arrow-icon"
                            />
                          </div>
                        </div>
                      </Link>
                    </article>
                  ))}
                  <div className="recipe-item recipe-item--fake" />
                  <div className="recipe-item recipe-item--fake" />
                  <div className="recipe-item recipe-item--fake" />
                </div>
                {displayLoadMore && (
                  <div className="load-more content-padding">
                    <div
                      onClick={handleLoadNextClick}
                      className="load-more-button c-button--green"
                    >
                      Načíst další
                    </div>
                  </div>
                )}
              </section>
            </div>
          </div>
          <BookShowCaseSection />
          <InstagramSection isSmallDevice={isSmallDevice} />
          <PartnersAwardsNewsletterMobile />
          <AboutMeSection />
        </Layout>
        <span className="site-underline site-underline--bottom">
          Copyright © 2020 Inna Valoušková & Smoothweb.cz
        </span>
      </div>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiArticle(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          title
          createdAt
          originallyCreatedAt
          tags {
            id
            Tag
          }
          thumbnailImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allStrapiRecipe(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          title
          createdAt
          originallyCreatedAt
          tags {
            id
            Tag
          }
          thumbnailImage {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allStrapiTag {
      edges {
        node {
          id
          Tag
        }
      }
    }
  }
`;

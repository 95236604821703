import React from "react";

import underline from "images/icons/zigzag-path.svg";
import cookbook from "images/images/kucharka.png";

export const BookShowCaseSection = () => (
  <section className="book-showcase content-padding">
    <div className="page-width-restrictor content-padding">
      <div className="image-content">
        <div className="image-content-spacing">
          <div className="image-centering">
            <img
              className="desktop-image"
              src={cookbook}
              alt="Kuchařka - Rok s Garden & Kitchen"
            />
          </div>
        </div>
      </div>
      <div className="text-content">
        <div className="text-content-spacing">
          <h2 className="heading">
            <div>Kuchařka</div> <div> Rok s Garden & Kitchen</div>
          </h2>
          <img src={underline} alt="ikona podtrhnutí" className="underline" />
          <p className="description-text">
            A je to tady! Plním si svůj sen a vydávám vlastní kuchařku, která je
            napojená na roční období a zahradu. Naleznete v ní spoustu inspirace
            a osvědčených receptů.
          </p>
          <div className="mobile-image d-lg-none">
            <img src={cookbook} alt="Kuchařka - Rok s Garden & Kitchen" />
          </div>
          <div className="cookbook-btn-wrapper">
            <a
              target="_blank"
              href="https://www.gkshop.cz/kucharka-rok-s-garden-kitchen/"
              className="button c-button--green cookbook-btn"
            >
              Koupit Kuchařku
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

import React from "react";

import underline from "images/icons/zigzag-path.svg";
import logo from "images/logo/garden-and-kitchen-logo.svg";
import profilePhoto from "images/images/inna-valouskova.jpg";

export const HeroSection = () => (
  <section className="hero">
    <div className="logo-wrap">
      <img src={logo} alt="Garden and Kitchen logo" className="logo" />
    </div>
    <div className="content-background">
      <div className="content-box content-padding page-width-restrictor">
        <div className="text-content">
          <h1 className="heading">
            <span className="nowrap">Recepty a zahrádka</span>
            <br />
            <span className="nowrap">od blogerky</span>
            <br />
            <span className="nowrap">Garden and Kitchen</span>
          </h1>
          <img src={underline} alt="ikona podtrhnutí" className="underline" />
          <p className="introduction-text">
            Vítám vás na mém blogu! Najdete zde obsáhlou sbírku mých oblíbených
            receptů a také tipy o zahradničení.
          </p>
        </div>
        <div className="photo-content">
          {/* Mobile version */}
          <div className="ratio-box-spacing" />
          <div className="ratio-box-wrap">
            <div className="ratio-box">
              <img
                src={profilePhoto}
                alt="Inna Valoušková"
                className="profile-photo"
              />
            </div>
          </div>
          {/* Desktop version */}
          <div className="d-space-setter">
            <div className="d-width-setter">
              <div className="d-centering">
                <div className="d-profile-photo-container">
                  <img
                    src={profilePhoto}
                    alt="Inna Valoušková"
                    className="d-profile-photo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="content-padding">
      <div className="ratio-box-spacing" />
    </div>
  </section>
);

import React from "react";

import foodblogLogo from "images/logo/foodblog-roku-logo.svg";
import smoothwebLogoFull from "images/logo/smoothweb-logo-full.png";

export const PartnersAwardsNewsletterMobile = () => (
  <section className="aside d-xl-none content-padding">
    <div className="item">
      <h3 className="heading">DOSTÁVAT NOVINKY NA EMAIL</h3>
      <span className="underline" />
      <div className="form-wrap">
        <form
          id="email-form--newsletter-mobile"
          className="c-form-container"
          action="https://gardenandkitchen.us18.list-manage.com/subscribe/post?u=0128f845864762bd651f99b9c&amp;id=e6e4646fa5"
          method="POST"
          acceptCharset="UTF-8"
          target="_blank"
          noValidate
        >
          <input
            id="email-form--newsletter-mobile--email"
            className="input"
            type="email"
            name="email"
            placeholder="Váš email"
            autoComplete="on"
            maxLength={255}
            required
          />
          <input
            id="email-form--newsletter-mobile--gotcha"
            type="hidden"
            name="_gotcha"
          />
          <button className="button c-button--green" type="submit">
            Odeslat
          </button>
          <div className="form-success">
            <span className="form-success-text">✓ Úspěšně odesláno</span>
          </div>
        </form>
      </div>
    </div>
    <div className="item item">
      <h3 className="heading">
        OCENĚNÍ <span>(Výherkyně)</span>
      </h3>
      <span className="underline" />
      <div className="cooperation-wrap">
        <a
          href="https://www.foodblogroku.cz/vysledky/vysledky-2017"
          target="_blank"
          className="cooperation-link"
        >
          <div className="centering">
            <img
              src={foodblogLogo}
              alt="foodblog roku logo"
              className="cooperation-image foodblog-logo"
            />
            <p className="description-text link">
              <span className="bold">AEG MAMA DISH 2017</span>
            </p>
          </div>
        </a>
      </div>
    </div>
    <div className="item item--last">
      <h3 className="heading">Partneři</h3>
      <span className="underline smoothweb" />
      <div className="cooperation-wrap">
        <a
          href="https://www.smoothweb.cz"
          target="_blank"
          className="cooperation-link"
        >
          <div className="centering">
            <img
              src={smoothwebLogoFull}
              alt="smoothweb tvorba webovych stranek a webovych aplikaci - logo"
              className="cooperation-image smoothweb-logo"
            />
          </div>
        </a>
      </div>
    </div>
  </section>
);

import React from "react";

import underline from "images/icons/zigzag-path.svg";
import rightIcon from "images/icons/arrow-right-icon.svg";
import instagramIcon from "images/icons/instagram-icon.svg";

import instagram1 from "images/instagramFeed/1.jpg";
import instagram2 from "images/instagramFeed/2.jpg";
import instagram3 from "images/instagramFeed/3.jpg";
import instagram4 from "images/instagramFeed/4.jpg";
import instagram5 from "images/instagramFeed/5.jpg";
import instagram6 from "images/instagramFeed/6.jpg";
import instagram7 from "images/instagramFeed/7.jpg";
import instagram8 from "images/instagramFeed/8.jpg";
import instagram9 from "images/instagramFeed/9.jpg";
import instagram10 from "images/instagramFeed/10.jpg";
import instagram11 from "images/instagramFeed/11.jpg";
import instagram12 from "images/instagramFeed/12.jpg";

type Props = {
  isSmallDevice: any;
};

export const InstagramSection = (props: Props) => {
  const instagramPhotos = [instagram1, instagram2, instagram3, instagram4, instagram5, instagram6, instagram7, instagram8, instagram9, instagram10, instagram11, instagram12];

  // TODO: rewrite for new instagram API
  // const [instagramData, setInstagramData] = useState([]);
  // useEffect(() => {
  //   fetch("https://www.instagram.com/garden.and.kitchen/?__a=1")
  //     .then((resp) => resp.json())
  //     .then((fetchdata) => {
  //       const slicedData = fetchdata.graphql.user.edge_owner_to_timeline_media.edges.slice(0, props.isSmallDevice ? 6 : 18);
  //       setInstagramData(slicedData);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  return (
    <section className="instagram">
      <h2 className="heading">Instagram</h2>
      <img src={underline} alt="ikona podtrhnutí" className="underline" />
      <div className="order-wrap page-width-restrictor">
        <div id="instagram_container" className="instagram-container">
          {instagramPhotos.map((photo, i) => (
            <div key={i} className="instagram-item ">
              <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/garden.and.kitchen/">
                <div className="instagram-item__content">
                  <img src={photo} alt="Inna Valoušková - fotka z instagramu" className="instagram-image" />
                </div>
              </a>
            </div>
          ))}
        </div>
        <a id="instagram_link" className="instagram-button" href="https://www.instagram.com/garden.and.kitchen/" target="_blank" rel="noopener noreferrer">
          <img src={instagramIcon} alt="instagram ikona" className="instagram-logo" />
          <span className="button-text">Otevřít můj instagram</span>
          <img src={rightIcon} alt="ikona šipky doprava" className="arrow-icon" />
        </a>
      </div>
    </section>
  );
};

import React from "react";

import eshopImage from "images/icons/eshop-illustration.svg";

type Props = {
  onDesktop: boolean;
};

export const EshopSection = (props: Props) => (
  <div className="item">
    {props.onDesktop ? (
      <div className="text-with-decoration">
        <span className="lateral-underline" />
        <h3 className="heading">MŮJ ESHOP</h3>
      </div>
    ) : (
      <>
        <h3 className="heading">MŮJ ESHOP</h3>
        <span className="underline" />
      </>
    )}
    <p className="description-text">
      V mém eshopu najdete k dostání moji knížku v tištěné formě, kterou Vám
      doručíme až domů.
    </p>
    <div className="eshop-footer-wrap">
      <img src={eshopImage} alt="ikona eshopu" className="eshop-image" />
      <a
        target="_blank"
        href="https://www.gkshop.cz/kucharka-rok-s-garden-kitchen/"
        className="button c-button--green"
      >
        Navštívit eshop
      </a>
    </div>
  </div>
);
